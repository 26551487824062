import React from 'react';
import Twitter from '../images/icon-twitter-blue.svg';
import LinkedIn from '../images/icon-linkedin-blue.svg';

const SocialMediaIcons = ({twitterLink, linkedInLink}) => (
  <div className="flex items-center mt-2">
    {twitterLink && (
      <a className="mr-4" href={twitterLink} target="_blank" rel="noreferrer">
        <img className="w-[25px]" src={Twitter} alt="on twitter" />
      </a>
    )}
    <a href={linkedInLink} target="_blank" rel="noreferrer">
      <img className="w-[25px]" src={LinkedIn} alt="on linkedin" />
    </a>
  </div>
);
export default SocialMediaIcons;
